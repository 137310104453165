<template>
    <div id="pageTable">
        <v-breadcrumbs :items="itemsLinks">
            <template v-slot:divider>
                <v-icon>chevron_right</v-icon>
            </template>
        </v-breadcrumbs>
        <v-container  grid-list-xl fluid>
            <v-row>
                <v-col cols="12" lg="12" class="ml-2">
                    <data-table
                        ref="tableDispositivos"
                        :tableName="'Lista de empleados registrados'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="false"
                        :isSelectable="true"
                        :unselected="unselected_general"
                        @selectAllEvent="selectAll"
                        :valCheck="'empleado_id'"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            fab
                                            color="#FFFFFF"
                                            small
                                            elevation="0"
                                            dark
                                            @click="eliminarSeleccionados()"
                                        >
                                            <v-icon color="rgba(0, 0, 0, 0.54)" class="outlined_v_icon">delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Eliminar seleccionados</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.empleado_id" v-for="(item, index) in data">
                                <td style="width:115px !important; padding-right: 4px;">
                                    <div >
                                        <v-checkbox class="mb-5" primary hide-details :value="item.empleado_id" v-model="selected_general" ></v-checkbox>
                                    </div>
                                </td> 
                                <td>
                                    <div>{{item.clave_empleado}}</div>
                                </td>
                                <td> 
                                    <div>{{item.nombre_empleado}}</div>
                                </td>
                                <td>
                                    <div>{{item.telefono}}</div>
                                </td>
                                <td>
                                    <div>{{item.correo}}</div>
                                </td>
                                <td>
                                    <div class="tblOpcionesBtn">
                                        <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                            v-on="on"
                                            class="botonHover"
                                            icon
                                            fab
                                            small
                                            dark
                                            @click="eliminar(item)"
                                            >
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dispositivosApi from "../api/dispositivos";
import Notify from "@/plugins/notify";
import Datatable from "@/components/datatable/Datatable.vue";

export default {
    name: "EmpleadosDispositivo",
    components: {
        "data-table": Datatable,
    },
    data() {
        return {
            url: "reloj-checador/empleados",
            columns: [
                { label: "Clave", name: "clave_empleado", filterable: true },
                { label: "Nombre", name: "nombre_empleado", filterable: true },
                { label: "Teléfono", name: "telefono", filterable: true },
                { label: "Correo", name: "correo", filterable: true },
                { label: "Opciones", name: "opciones", filterable: false, align:"center" },
            ],
            filters: { checador_id: null },
            loading_guardar: false,
            loading: true,
            dialog: false,
            search: "",
            nombre_checador : "",
            dispositivo     : null,
            url_checador    : "",
            token_checador  : "",
            itemsLinks: [
                {
                    text: "Dispositivos biométricos",
                    disabled: false,
                    to: "/dispositivos"
                },
                {
                    text: "Lista de empleados registrados",
                    disabled: true,
                    to: "/checador_relacion"
                }
            ],
            selected_general        : [],
            unselected_general      : [],
            masivo                  : false,
            checkAll                : false,
        };
    },
    computed: {
        ...mapState("auth", ["datos", "user"])
    },
    watch:{
        selected_general:function(value) {
            if(value.length > 0 ){
                this.masivo =  true;
            }
            else{
                this.masivo =  false;
            }
        },
    },
    methods: {
        listar() {
            this.token_checador = localStorage.getItem("token_checador");
            if (!this.$session.get('emplChecador')) {
                //this.$router.push("/dispositivos");
            } else {
                this.loading = true;
                this.filters.checador_id = this.dispositivo.datos.id;
                this.nombre_checador =  this.dispositivo.datos.descripcion;
            }
            $("tr th:nth-child(1)").css("width", "135px");
        },
        eliminar(datos) {
            let self = this;
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el colaborador?",
                "Eliminar",
                () => {
                    let empleados = [];
                    empleados.push(datos.empleado_id)
                    let param = {
                        checador_id: parseInt(this.dispositivo.datos.id),
                        empleados: empleados,
                    };
                    dispositivosApi.dropEmpleado(param).then(response => {
                        this.listar();
                        this.respuesta_ok("se ha eliminado el colaborador");
                    })
                    .catch(err => {
                        this.respuesta_error(err);
                    });
                }
            );
        },
        eliminarSeleccionados(){

            if(this.selected_general.length == 0){
                return
            }
            let parametros = { checador_id: parseInt(this.dispositivo.datos.id), empleados : this.selected_general}

            let frase1 = "";
            let frase2 = "";
            let frase3 = "";

            if(this.selected_general.length == 1){
                frase1 = "Eliminar empleado"
                frase2 = "¿Estás seguro que deseas eliminar el empleado seleccionado?"
                frase3 = "El empleado se elimino satisfactoriamente."
                
            }
            else if(this.selected_general.length > 1){
                frase1 = "Eliminar empleados"
                frase2 = "¿Estás seguro que deseas eliminar los empleados seleccionados?"
                frase3 = "Los empleados se eliminaron satisfactoriamente."
            }

            Notify.Alert(
                frase1,
                frase2,
                "Eliminar",
                () => {

                    dispositivosApi.dropEmpleado(parametros).then(response => {
                        Notify.Success("Operación exitosa", frase3);
                        this.$refs.tableDispositivos.getData();
                        this.$refs.tableDispositivos.unselectAll();
                        this.selected_general = [];
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }      
                    });
                }
            )
        },
        respuesta_error(err) {
            console.log(err);
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }
                Notify.ErrorToast(leyenda);
            }
        },
        respuesta_ok(msj = "") {
            Notify.Success("Operación exitosa", msj);
            this.$refs.tableDispositivos.getData();
        },
        selectAll(isSelected, items) {
            this.checkAll = isSelected;
            this.selected_general = items;

            if(!isSelected){
                this.unselected_general = [];
            }
        },
    },
    mounted() {
        this.listar();
    },
    beforeCreate(){
        if(!this.$session.get('emplChecador')){
            this.$router.push('/dashboard');
        }
    },
    created(){
        this.dispositivo = this.$session.get("emplChecador");
        this.listar();
    },
    updated() {
        document.querySelector('#tableScroll thead tr th:nth-child(1)').style.paddingRight   = "4px";
        document.querySelector('#tableScroll thead tr th:nth-child(1)').style.width   = "80px";
        document.querySelector('#tableScroll thead tr th:nth-child(2)').style.paddingLeft   = "16px";
    }
};
</script>
